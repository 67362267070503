import React, { Component } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Modal, Container, Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap'
import { sbaserver } from '../helpers/SBAServer'
import videojs from 'video.js'

class Player extends Component {

    constructor(props) {
        super(props)

        this.state = { src: null, observeTimer: null, playerActive: false, testActive: true }
        //this.state = { src: null, observeTimer: null, playerActive: false }
        this.player = null
    }

    componentWillMount() {
        sbaserver.on('changeURL', (data) => {
            console.log("New URL received: ", data)
	    this.setState({src: data, playerActive: true, testActive: false})
            this.player.src([{src: data}])
            // Got a master override; play whatever the server says to play
            this.player.play()
        })

    }

    componentDidMount() {
        // We want to check user storage and auto-play our last URL
        //
       

        let self = this
        this.player = videojs(this.videoNode, { autoPlay: true, controls: true, fluid: true, sources: null, seekable: true, xWebkitAirplay: "allow"},       
            function onPlayerReady() {
                  let observeTimer = setInterval(self.observePlayer.bind(self), 10000)
                  self.setState({observeTimer})
                  sbaserver.emit('status', { 
                     ready: true, url: self.state.src, rate: this.playbackRate() 
                })
                sbaserver.emit('queryLive', { 
                    ready: true, url: self.state.src, rate: this.playbackRate() 
                })
             })
            // We should ask for the live URL, but until then, display the demo videos
            //console.log('onPlayerReady', this)

	

        if (this.state.src && this.state.playerActive) { this.player.play(); }

    }

    observePlayer() {
    
        if (!!this.player && this.state.playerActive) {
            let timeObj = { 
                elapsed: this.player.currentTime(), 
                duration: this.player.duration(), 
                remaining: this.player.remainingTime(), 
                live: this.player.liveTracker.isLive(), 
                size: { 
                    width: this.player.videoWidth(), 
                    height: this.player.videoHeight()
                }
            }
            //console.log("Sending time update: ", timeObj)
            sbaserver.emit('time', timeObj)

	     let statusObj = { 
		ready: true, url: this.player.src(), rate: this.player.playbackRate(),
		playPause: this.player.paused() ? "paused" : (this.player.playbackRate() === 1 ? "playing" : "unknown"),
		playerStatus: this.player.readyState() > 2 ? "Ready to Play" : "Can't play",
		lastError: "", lastNotification: "", lastPathChanged: ""
	    }
	    sbaserver.emit('status', statusObj)
	console.log("Sending status: ", statusObj)
	}
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
            clearInterval(this.state.observeTimer)
        }
        
    }

    handleContentSelection(selection) {
        this.setState({playerActive: true, src: selection.url})
        this.player.src([{src: selection.url}])
        this.player.play()
    }

    render() {

        
        let testVideos = (
            <video autoPlay playsInline loop muted id="bgVideo" style={{zIndex: '-100', position: 'absolute', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}>
                <source src="stakeconfbackgroundvideo.mp4" type="video/mp4"></source>
            </video>
        )

        let overLay = (
            <div className="fadeIn" style={{position: 'absolute', left: '8%', top: '56%', width: '33%', height: '35%', color: 'white', padding: '2%', background: 'rgba(0, 0, 0, 0.4)', borderRadius: '4px', boxShadow: '0 0 3px 5px rgba(0, 0, 0, 0.4)', zIndex: 1000}}>
                        
            <p style={{fontSize: '3vmin', position: 'absolute', left: '5%', top: '10%', width: '90%', height: 'auto', cursor: 'pointer'}}
             onClick={this.handleContentSelection.bind(this,{url: "https://sirb.me:8080/vod/norway.m3u8"})}>Norway Demo</p>
            

            </div>
        )

//
//	            <p style={{fontSize: '3vmin', position: 'absolute', left: '5%', top: '40%', width: '90%', height: 'auto', cursor: 'pointer'}}
//             onClick={this.handleContentSelection.bind(this,{url: "https://sirb.me:8080/vod/4ksampler-sw.m3u8"})}>StockFootage Demo</p>
//            
//            <p style={{fontSize: '3vmin', position: 'absolute', left: '5%', top: '70%', width: '90%', height: 'auto', cursor: 'pointer'}}
//             onClick={this.handleContentSelection.bind(this,{url: "https://sirb.me:8080/vod/tabchoiraugust.m3u8"})}>Tabernacle Choir</p>
//

	
        let videoChunk = (
        
            <div style={{visibility: this.state.playerActive ? 'visible' : 'hidden'}}>
            <video ref={ node => this.videoNode = node }  
                 style={{ position: 'absolute', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}  
                 className="video-js" >
            </video>
            </div>
            )

        return (
            <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black'}}>
                
                <div style={{position: 'absolute', top: '50%', left: '50%',  paddingBottom: '56.25%', maxHeight: '100%', width: '100%', transform: 'translate(-50%, -50%)', WebKitTransform: 'translate(-50%, -50%)', border: '0px dotted orange'}}>
                { !this.state.playerActive ? testVideos : null}
                { this.state.testActive ? overLay : null }
                { videoChunk }

                </div>
                
                
            </div>
        );
    }

}


export default Player;
