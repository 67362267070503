import React, { Component } from 'react'

import videojs from 'video.js'

export default class JSPlayer extends Component {

    constructor(props) {
        super(props)
        //this.state = { src: null, observeTimer: null, playerActive: false }
	this.player = null
	
	this.onReady = this.onReady.bind(this)
	this.playbackRate = this.playbackRate.bind(this)
	this.currentTime = this.currentTime.bind(this)
	this.duration = this.duration.bind(this)
	this.remainingTime = this.remainingTime.bind(this)
	this.videoWidth = this.videoWidth.bind(this)
	this.videoHeight = this.videoHeight.bind(this)
	this.play = this.play.bind(this)
	this.pause = this.pause.bind(this)
	this.src = this.src.bind(this)
	this.paused = this.paused.bind(this)
	this.reset = this.reset.bind(this)
	this.readyState = this.readyState.bind(this)
	this.liveTracker = { isLive: () =>  !!this.player ? this.player.liveTracker.isLive() : false }

    }


    componentDidMount() {
        // We want to check user storage and auto-play our last URL
        //

        this.player = videojs(this.videoNode, { autoPlay: true, controls: true, fluid: true, sources: null}, this.onReady)
	
        // We should ask for the live URL, but until then, display the demo videos
        //console.log('onPlayerReady', this)

    }

    componentDidUpdate(prevProps) {
	if (prevProps.src !== this.props.src) {
	    this.player.src(this.props.src)
	    this.props.onReady(this)
	}
    }


    onReady() {
    	this.player.src(this.props.src)
    	this.props.onReady(this)
    }

    // Wrapper functions around videojs player
    
    playbackRate() {
	return this.player.playbackRate()
    }
    
    currentTime() {
	return this.player.currentTime()
    }

    duration() {
	return this.player.duration()
    }

    remainingTime() {
	return this.player.remainingTime()
    }

    videoWidth() {
	return this.player.videoWidth()
    }

    videoHeight() {
	return this.player.videoHeight()
    }

    paused() {
	return this.player.paused()
    }

    readyState() {
	return this.player.readyState()
    }

    reset() {
	return this.player.reset()
    }

    play() {
	return this.player.play()
    }

    pause() {
	return this.player.pause()
    }

    src() {
	return this.player.src()
    }

    
    // end wrapper

    
    componentWillUnmount() {
	if (this.player) {
	    this.player.dispose()
	}
    }
        
    
    render() {

	return (
	    <div data-src={this.props.src}>
            <video ref={ node => this.videoNode = node }  
            style={{ position: 'absolute', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}
	    autoPlay playsInline
                 className="video-js" >
            </video>
            </div>
	)
	
    }

}



