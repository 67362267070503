import React, { Component } from 'react'

import YouTube from 'react-youtube'

export default class YTPlayer extends Component {

    constructor(props) {
	super(props)
	this.player = null

	this.onReady = this.onReady.bind(this)
	this.playbackRate = this.playbackRate.bind(this)
	this.currentTime = this.currentTime.bind(this)
	this.duration = this.duration.bind(this)
	this.remainingTime = this.remainingTime.bind(this)
	this.videoWidth = this.videoWidth.bind(this)
	this.videoHeight = this.videoHeight.bind(this)
	this.play = this.play.bind(this)
	this.paused = this.paused.bind(this)
	this.readyState = this.readyState.bind(this)
	this.liveTracker = { isLive: function() { return false } }
	
	
    }

    onReady(event) {
	let player = event.target
	this.player = player
	this.props.onReady(this)
    }

    // Wrapper functions around YouTube player
    
    playbackRate() {
	return this.player.getPlaybackRate()
    }
    
    currentTime() {
	return this.player.getCurrentTime()
    }

    duration() {
	return this.player.getDuration()
    }

    remainingTime() {
	return this.player.getDuration() - this.player.getCurrentTime() // will this work?
    }

    videoWidth() {
	return 1280;
    }

    videoHeight() {
	return 720;
    }

    paused() {
	return this.player.getPlayerState() === 2
    }

    readyState() {
	return this.player.getPlayerState()
    }

    play() {
	console.log("Asked to play")
	return this.player.playVideo()
    }


    // end wrapper
    
    render() {
	let opts = { width: '100%', height:'720px',  className: 'video-js', playerVars: { autoplay: 1 }}
	console.log("Requesting a YouTube player for ID ", this.props.videoId)
	return <YouTube opts={opts} videoId={this.props.videoId} onReady={this.onReady} />
    }

}
