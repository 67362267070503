import React, { Component } from 'react'
//import { StyleSheet, View, Text } from 'react-native'
//import { Modal, Container, Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap'
import { sbaserver } from '../helpers/SBAServer'

import JSPlayer from './JSPlayer'
import YTPlayer from './YTPlayer'

export default class PlayerWrapper extends Component {

    constructor(props) {
	super(props)
	this.state = { newDate: Date.now(), src: null,  observeTimer: null, playerActive: false, testActive: true, player: null, playerType: "js" }
//	this.player = null
	this.observePlayer = this.observePlayer.bind(this)
	this.handleOnReady = this.handleOnReady.bind(this)
    }

    componentDidMount() {
        sbaserver.on('changeURL', (data) => {
			console.log ("Got URL change")
	    	this.setState({ testActive: false, playerActive: true })
		    this.handleSRCChange(data)
		})
		sbaserver.on('systemQueryLive', () => {
			sbaserver.emit('queryLive', {
				ready: false, url: this.state.src || "na", rate: 0, host: window.location.hostname, path: window.location.pathname,
			})
		})
	
		let observeTimer = setInterval(this.observePlayer, 10000)
		this.setState({ observeTimer})
		
		console.log("Sending queryLive and initial status")
		sbaserver.emit('queryLive', {			
		    ready: false, url: this.state.src || "na", rate: 0, host: window.location.hostname, path: window.location.pathname,
		})
        sbaserver.emit('status', { 
            ready: true, url: this.state.src || "na", rate: 0, playPause: "paused", host: window.location.hostname, path: window.location.pathname,
        })

		if (this.state.src && this.state.playerActive && this.state.player)  { this.state.player.play() }
    }

    componentWillUnmount() {
        clearInterval(this.state.observeTimer)
		sbaserver.emit('status', { 
            ready: false, url: "na", rate: 0, playPause: "stopped", playerStatus: "Disconnected", lastError: "Disconnecting", lastNotification: "Closing browser"
        })
	
    }
    
    handleSRCChange(src) {
		console.log("New URL received: ", src)
		if (!!this.state.player && this.state.player.readyState() === 2) {
			this.state.player.reset()
		}
		//this.setState({playerActive: false})
		const pattern = /youtu.*?([a-zA-Z0-9_-]{11})/
		let ytidmatch = pattern.exec(src)
//	console.log("ytidmatch: ",ytidmatch)
		if (ytidmatch) {
	    // we got a YouTube ID
	    	console.log("YTid: ",ytidmatch[1])
	    	this.setState({ytid: ytidmatch[1], playerType: "yt"})
		}  else {
	    	if (this.state.playerType === "yt") {
				this.setState({ytid: null, playerType: "js", player: null})
	    	} else {
				this.setState({ytid: null})
	    	}
		}
		
		this.setState({src, playerActive:true}, () => {
			if (!!this.state.player) { this.state.player.play() }
		})
	}
    
    handleOnReady(player) {
		//console.log("Got player object:", player)

		this.setState({ player,  testActive: false })
	
        sbaserver.emit('status', { 
            ready: true, url: this.state.src, rate: player.playbackRate(), host:  window.location.hostname, path: window.location.pathname
        })

		if (this.state.src && this.state.playerActive) {
			//console.log("playing src " + this.state.src)
			player.play()
		}
    }

    handleContentSelection(selection) {
	this.setState({ testActive: true})
	this.handleSRCChange(selection.url)
    }

    observePlayer() {
	let player = this.state.player
//	console.log("sending status or time")
	if (!!player && this.state.playerActive) {
	    try {
		let timeObj = { 
                    elapsed: player.currentTime(), 
                    duration: player.duration(), 
                    remaining: player.remainingTime(), 
                    live: player.liveTracker.isLive(), 
                    size: { 
			width: player.videoWidth(), 
			height: player.videoHeight()
                    }
		}
		//console.log("Sending time update: ", timeObj)
		sbaserver.emit('time', timeObj)
		
		let readyState = player.readyState()
		let statusObj = { 
		    ready: true, url: this.state.src, rate: player.playbackRate(),
		    playPause: player.paused() ? "paused" : (player.playbackRate() === 1 ? "playing" : "unknown"),
		    playerStatus: readyState > 2 || readyState === 1 ? "Ready to Play" : "Can't play",
			lastError: "", lastNotification: "", lastPathChanged: "",
			host:  window.location.hostname, path: window.location.pathname
		}
		//console.log("readyState ",player.readyState())
		sbaserver.emit('status', statusObj)
	    } catch(err) {
		console.log("Unable to send time/status update")
	    }
	} else {
//	    console.log("player: ", player)
//	    console.log("playerActive: ",this.state.playerActive)
	    sbaserver.emit('status', { 
		ready: true, url: this.state.src || "na", rate: 0, playPause: "paused", playerStatus: "Waiting to Play",
		host:  window.location.hostname, path: window.location.pathname
            })

	    sbaserver.emit('time', { 
		elapsed: 0, duration: 0, remaining: 0, live: false, size: { width: 0, height: 0 }
            })

	}
    }



    render() {


	const src = this.state.src; 
	const newDate = this.state.newDate;
        
        let testVideos = (
            <video autoPlay playsInline loop muted id="bgVideo" style={{zIndex: '-100', position: 'absolute', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%'}}>
                <source src="stakeconfbackgroundvideo.mp4" type="video/mp4"></source>
            </video>
        )

        let overLay = (
            <div className="fadeIn" style={{position: 'absolute', left: '8%', top: '56%', width: '33%', height: '35%', color: 'white', padding: '2%', background: 'rgba(0, 0, 0, 0.4)', borderRadius: '4px', boxShadow: '0 0 3px 5px rgba(0, 0, 0, 0.4)', zIndex: 1000}}>
                        
            <p style={{fontSize: '3vmin', position: 'absolute', left: '5%', top: '10%', width: '90%', height: 'auto', cursor: 'pointer'}}
             onClick={this.handleContentSelection.bind(this,{url: "https://manage.simplebroadcast.app:8080/vod/norway.m3u8"})}>Norway Demo</p>
            

            </div>
        )

	return (
            <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black'}}>                
            <div style={{position: 'absolute', top: '50%', left: '50%',  paddingBottom: '56.25%', maxHeight: '100%', width: '100%', transform: 'translate(-50%, -50%)', WebKitTransform: 'translate(-50%, -50%)', border: '0px dotted orange'}}>
			<div style={{display: 'none'}}> { newDate }</div>
            { this.state.playerActive ? this.state.playerType === "yt" ? <YTPlayer videoId={this.state.ytid} onReady={this.handleOnReady} />  : <JSPlayer src={src} newDate={newDate} onReady={this.handleOnReady} /> : ""}
            </div>
            </div>
			
	)

/*
           { !this.state.playerActive ? testVideos : null }
            { this.state.testActive ? overLay : null }
*/

	

	
    }
    


}
