import React, { Component } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import {  FormGroup, FormControl, Button, Jumbotron, Spinner, Card } from 'react-bootstrap'
import Player from './views/Player'
import PlayerWrapper from './views/PlayerWrapper'

import { sbaserver } from './helpers/SBAServer'
import { detect } from 'detect-browser'
import './index.css'

class App extends Component {

  constructor(props) {
    super(props)
    let userData = localStorage.getItem('userData')
    this.browser = detect();
    
    if (!!userData) {
      let actualData = JSON.parse(userData)
      this.state = {uuid: actualData.uuid, name: actualData.name, attendance: actualData.attendance, loggedIn: false }
      //this.onLoginPressed()
    } else {
      this.state = {  name: "", attendance: "", uuid: this.uuidv4(), loggedIn: false }
    }
    this.state.broadcastDescription = ""
    this.state.contentList = []

    this.handleChange = this.handleChange.bind(this)

  }

  componentDidMount() {
    sbaserver.emit('queryContent',{ host: window.location.hostname, path: window.location.pathname });
    //console.log("Sent queryContent")

    sbaserver.on("content",(data) => {
      console.log("Got content data: ", data)
      if (data.length == 1) {
        this.setState({broadcastDescription: !!data[0].description ? data[0].description : ""})
      } else if (data.length > 1) {
        this.setState({contentList: data, broadcastDescription: data.description,})
      }
    })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  onLoginPressed() {
    // Validate name and phone at some point
    let userData = { uuid: this.state.uuid, 
                    attendance: this.state.attendance, 
                    name: this.state.name }

    localStorage.setItem('userData', JSON.stringify(userData))
    sbaserver.emit('checkin', { type: this.browser.name, 
                                name: this.state.name, 
                                system: this.browser.os,
                                version: this.browser.version,
                                attendance: this.state.attendance,
                                uuid: userData.uuid,
                                host: window.location.hostname,
                                path: window.location.pathname
    } )

      sbaserver.on('checkinRequest',(data) => {
	  sbaserver.emit('checkin', { type: this.browser.name, 
				      name: this.state.name, 
				      system: this.browser.os,
				      version: this.browser.version,
				      attendance: this.state.attendance,
              uuid: userData.uuid,
              host: window.location.hostname,
              path: window.location.pathname
          } )
      })
      
    this.setState({loggedIn: true})
  }


  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
    });
}


  render() {


    let contentList = this.state.contentList

    let displayContentList = (
      <View style={styles.loginWide}>
      <Text style={styles.heading}>Welcome to the Grantsville Broadcast Center</Text>
      <hr />
      <Text style={styles.heading2}>Please select one of the following streams to view:</Text>
      <br />
     { contentList.map((content) => {
         return (<><Button variant="info" href={content.path} style={{margin: "3px", fontSize: "12pt"}}>{content.name}</Button></>)
       })
      }
      </View>
    )

    let waitbox = ( 
      <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0}}>                
      <div style={{position: 'absolute', top: '50%', left: '50%',  paddingBottom: '56.25%', maxHeight: '100%', width: '100%', maxWidth: '1280', transform: 'translate(-50%, -50%)', WebKitTransform: 'translate(-50%, -50%)'}}>
        <Card>
        <Text style={styles.heading}>Welcome to the Grantsville Broadcast Center</Text>
        <hr />
        <Text style={styles.heading2}>Please wait while we load available content...</Text>
        </Card>
        </div>
        </div>
    )

    let mainForm = (
      <View style={styles.login}>
      <Card>
      <Card.Header>
      <Text style={styles.heading}>{this.state.broadcastDescription ? this.state.broadcastDescription : "Welcome to the Broadcast"}</Text>
      </Card.Header>
      <Card.Body>
        <Card.Title>
      <Text style={styles.heading2}>Please enter your name and the number of viewers participating with you.</Text>
      </Card.Title>

      <FormGroup>
      <FormControl type="text" className="login" name="name"
      onChange={this.handleChange}
      value={this.state.name}
      placeholder="Name" />
      </FormGroup>

      <FormGroup>
      <FormControl type="text" className="login" name="attendance"
      onChange={this.handleChange}
      value={this.state.attendance}
      placeholder="# of Viewers" />
      </FormGroup>

      
      <Button className="login"  onClick={this.onLoginPressed.bind(this)} >Watch</Button>
      </Card.Body>
      </Card>
      </View>
  );

      //      let player = (<Player />)
    let player = (<PlayerWrapper />)


    return (
      <View style={styles.app}>
      { this.state.loggedIn ? player : contentList.length > 1 ? displayContentList : window.location.pathname.match(/[\w|\d]+/) ? mainForm : waitbox }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  app: {
    flex: 1,
    width: '100%',
    alignItems: 'center'

  },
  login: { 
    flex: 1,
    maxWidth: 450,
    width: '90%',
    margin: 10,
  },
  loginWide: {
    flex: 1,
    maxWidth: 600,
    width: '90%',
    margin: 5,
  },
  heading: {
    fontSize: 40,
    margin: 5,
    textAlign: "center",
    fontWeight: '100',

},
heading2: {
    fontSize: 28,
    textAlign: "center",
    fontWeight: '100',

},
heading3: {
    color: '#fcfff5',
    fontSize: 14,
    textAlign: "left",
    fontWeight: '100',
},


})

export default App;
